// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
//
// // createApp(App).use(store).use(router).mount('#app')
// // import installElementPlus from './plugins/element'
// import Axios from 'axios'
// import Global from './Global'
// import './assets/css/icon.css'
// import ElementUI from 'element-plus'
// import 'element-plus/theme-chalk/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// const app = createApp(App)
//
//
// app.config.globalProperties.$axios = Axios;
//
// // installElementPlus(app)
//
// for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(name, component);
// }
// app
//     .use(store)
//     .use(router)
//     .use(Global)
//     .use(ElementUI)
//     // .use(ElementPlusIconsVue)
//     .mount('#app')
//

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ganttastic from '@infectoone/vue-ganttastic'
import Axios from 'axios'

const app = createApp(App).use(store)
app.config.globalProperties.$axios = Axios;

for (const [name, comp] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, comp);
}
app.use(ElementPlus)
app.use(router).use(ganttastic)
app.mount('#app')
// createApp(App).use(router).mount('#app')
