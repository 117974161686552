export  default  {
    namespaced  :  true,

    state: {
        //保持当前选择的班级对象,返回时使用
        learn_CurrentClassObj: {},
        // learn_CurrentStudentHWObj: {},
        learn_CurrentStudentHWIndex: -1,
    },
    getters: {
        getLearnCurrentClassObj(state){
            return state.learn_CurrentClassObj
        },
        // getLearnCurrentStudentHWObj(state){
        //     return state.learn_CurrentStudentHWObj
        // },
        getLearnCurrentStudentHWIndex(state){
            return state.learn_CurrentStudentHWIndex
        }
    },
    mutations: {
        setLearnCurrentClassObj(state, data) {
            state.learn_CurrentClassObj = data;
        },
        // setLearnCurrentStudentHWObj(state, data) {
        //     state.learn_CurrentStudentHWObj = data;
        // },
        setLearnCurrentStudentHWIndex(state, data) {
            state.learn_CurrentStudentHWIndex = data;
        },
    },
    actions: {
        setLearnCurrentClassObj(context,data)  {
            context.commit('setLearnCurrentClassObj', data)
        },
    },
}