<template>
    <div class="sidebar">
        <div class="toggle-button" @click="toggleCollapse()">
            <!-- <el-icon :size="20" :class="{'is-active':isActive}"><Fold /></el-icon> -->
            <svg
                :class="{'is-active':isActive}"
                class="hamburger"
                viewBox="0 0 1024 1024"
                width="64"
                height="64"
            >
                <path
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"/>
            </svg>
        </div>
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="isCollapse"
            :collapse-transition="false"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            :unique-opened="true"
            ellipsis
            router
        >
            <el-sub-menu index="1">
                <template #title>
                    <el-icon><Notebook/></el-icon>
                    <span>档案管理</span>
                </template>
                <el-menu-item index="/profiles/100">
                    <el-icon><Avatar/></el-icon>
                    <span>管理员</span>
                </el-menu-item>
                <el-menu-item index="/profiles/2">
                    <el-icon><UserFilled/></el-icon>
                    <span>教师</span>
                </el-menu-item>
                <el-menu-item index="/profiles/1">
                    <el-icon><User/></el-icon>
                    <span>学生</span>
                </el-menu-item>
                <el-menu-item index="/guests">
                    <el-icon color="#409EFC"><QuestionFilled/></el-icon>
                    <span>角色</span>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="2">
                <template #title>
                    <el-icon><FolderOpened/></el-icon>
                    <span>课程管理</span>
                </template>
                <el-sub-menu index="2-1">
                    <template #title>
                        <el-icon><FolderAdd/></el-icon>
                        <span>课程关键字</span>
                    </template>
                    <el-menu-item index="/KeyLexile">
                        <el-icon><Key/></el-icon>
                        <span>Lexile关键字</span>
                    </el-menu-item>
                    <el-menu-item index="/KeyCourse">
                        <el-icon><Key/></el-icon>
                        <span>Course关键字</span>
                    </el-menu-item>
                    <el-menu-item index="/KeyLesson">
                        <el-icon><Key/></el-icon>
                        <span>Lesson关键字</span>
                    </el-menu-item>
                    <el-menu-item index="/KeySlides">
                        <el-icon><Key/></el-icon>
                        <span>Slides关键字</span>
                    </el-menu-item>
                </el-sub-menu>
                <el-menu-item index="/CourseCustom">
                    <el-icon><FolderChecked/></el-icon>
                    <span>课程制定</span>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="3">
                <template #title>
                    <el-icon><School/></el-icon>
                    <span>教室管理</span>
                </template>
                <el-menu-item index="/ClassInfo">
                    <el-icon><LocationInformation/></el-icon>
                    <span>教室信息</span>
                </el-menu-item>
                <el-menu-item index="/CityKey">
                    <el-icon><WindPower/></el-icon>
                    <span>城市代码</span>
                </el-menu-item>
                <el-menu-item index="/CampusName">
                    <el-icon><Paperclip/></el-icon>
                    <span>校区缩写</span>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="4">
                <template #title>
                    <el-icon><VideoCameraFilled/></el-icon>
                    <span>团课管理</span>
                </template>
                <el-menu-item index="/Groups">
                    <el-icon><CameraFilled/></el-icon>
                    <span>团课</span>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="5">
                <template #title>
                    <el-icon><Trophy/></el-icon>
                    <span>学习管理</span>
                </template>
                <el-menu-item index="/Learn">
                    <el-icon><OfficeBuilding/></el-icon>
                    <span>课堂学习</span>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="6">
                <template #title>
                    <el-icon><Memo/></el-icon>
                    <span>作业管理</span>
                </template>
                <el-menu-item index="/IpType">
                    <el-icon><EditPen/></el-icon>
                    <span>题目类型</span>
                </el-menu-item>
<!--                <el-menu-item index="/Ip">-->
<!--                    <el-icon><Tickets/></el-icon>-->
<!--                    <span>题目内容</span>-->
<!--                </el-menu-item>-->
            </el-sub-menu>

        </el-menu>
    </div>
</template>

<script>
// import bus from "../common/bus";
export default {
    name: 'Hamburger',
    data() {
        return {
            isCollapse: false,
            onRoutes: ''
        };
    },
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        onRoutes() {
            return this.$route.path;
        },
        // collapse() {
        //     return this.$store.state.isCollapse;
        // },
    },
    methods: {
        toggleCollapse() {
            this.isCollapse = !this.isCollapse
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.isCollapse = true
        }
    }
};
</script>

<style scoped>
.sidebar {
    /* display: block; */
    background-color: #324157;
    /* position: absolute; */
    left: 0;
    top: 80px;
    bottom: 0;
    overflow-y: scroll;
    height: calc(100vh);
}

.sidebar::-webkit-scrollbar {
    width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
    height: calc(100% - 80px);
     /*height: calc(100vh - 80px);*/
}

.sidebar > ul {
    height: 100%;
}

.toggle-button {
    background-color: #4A5064; /* 背景色(浅灰) */
    font-size: 10px; /* 字体大小10像素 */
    line-height: 24px; /* 行高24像素 */
    color: #fff; /* 字体颜色白色 */
    text-align: center; /* 字体居中*/
    letter-spacing: 0.2em; /* 字体之间的距离 */
    cursor: pointer; /* 鼠标的形状（手形） */
    border-style: none;
}

/* .toggle-icon {

} */
.hamburger {
    display: inline-block;
    vertical-align: middle;
    fill: #fff; /* 字体颜色白色 */
    width: 20px;
    height: 20px;
}

.hamburger.is-active {
    transform: rotate(180deg);
}

.el-menu-container:not(.el-menu--collapse) {
    width: 200px;
    /* height: calc(100vh); */
    height: 100%;
}

</style>
