<template>
    <nav>
        <!-- <router-link to="/">Home</router-link> | -->
        <!-- <router-link to="/about">About</router-link> -->
    </nav>
    <router-view/>
</template>

<script>
export default {
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            bodyWidth: 0,
        }
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                window.fullWidth = document.documentElement.clientWidth;
                that.windowHeight = window.fullHeight;  // 高
                that.windowWidth = window.fullWidth; // 宽
            })()
        }
        that.bodyWidth = this.windowWidth

        // console.log("this.bodyWidth:",this.bodyWidth)
    },
    watch: {
        windowHeight (val) {
            let that = this;
            // that.tabHeight = that.windowHeight - 230
            // console.log("实时屏幕高度：",val, that.windowHeight );
            // console.log("tabHeight：",val, that.tabHeight );
        },
        windowWidth (val) {
            let that = this;
            that.bodyWidth = this.windowWidth
            // console.log("this.bodyWidth:",this.bodyWidth)
            // console.log("实时屏幕宽度：",val, that.windowHeight );
        },
    },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */

body {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: #f1f1f1;
}

.content-container {
    background-color: white;
    padding: 10px;
    margin: 0;
    border-radius: 10px;
    min-width: 1200px;
    /*display: inline-block;*/
    /*width: v-bind(bodyWidth);*/
    width: calc(100vw - 250px - 100px);
}

.input-tip {
    margin: 5px 0 0 0;
    line-height: 20px;
    width: 80px;
    font-size: 14px;
}

.input-tip-120 {
    margin: 5px 0 0 0;
    line-height: 20px;
    width: 120px;
    font-size: 14px;
}

.input-tip-160 {
    margin: 5px 0 0 0;
    line-height: 20px;
    width: 160px;
    font-size: 14px;
}

.input-field {
    margin-right: 10px;
    line-height: 20px;
    width: 100px;
}
.input-field-80 {
    margin-right: 10px;
    line-height: 20px;
    width: 80px;
}

.input-field-200 {
    margin-right: 10px;
    line-height: 20px;
    width: 200px;
}
.input-field-220 {
    margin-right: 10px;
    line-height: 20px;
    width: 220px;
}
.input-field-400 {
    margin-right: 10px;
    line-height: 20px;
    width: 400px;
}
.input-field-450 {
    margin-right: 10px;
    line-height: 20px;
    width: 450px;
}

.input-field-text {
    margin-right: 10px;
    line-height: 20px;
    width: 150px;
}

.content-row {
    margin-bottom: 10px;
}

.el-row {
    margin-bottom: 0;
}

.el-row:last-child {
    margin-bottom: 0;
}

.el-col {
    border-radius: 4px;
}

.grid-content {
    border-radius: 4px;
    /* min-height: 36px; */
}

.content-container-tab {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin: -10px;
    width: v-bind(bodyWidth);
    /*height: v-bind(bodyHeight);*/
}

</style>
