export  default  {
    namespaced  :  true,

    state: {
        //保持当前选择的班级对象,返回时使用
        studentsList: '',
        allList: '',
    },
    getters: {
        getStudentsList(state){
            return state.studentsList
        },
        getAllList(state){
            return state.allList
        },
    },
    mutations: {
        setStudentsList(state, data) {
            state.studentsList = data;
        },
        setAllList(state, data) {
            state.allList = data;
        },
    },
    actions: {
        setStudentsList(context,data)  {
            context.commit('setStudentsList', data)
        },
        setAllList(context,data)  {
            context.commit('setAllList', data)
        },
    },
}