<template>
    <el-container class="el-container-1">
        <el-header>
            <v-header />
        </el-header>
        <el-container>
            <el-aside width="auto">
                <v-sidebar />
            </el-aside>
            <!-- <el-main class="content-box" :class="{ 'content-collapse': collapse }"> -->
            <el-main class="content-main">
                <v-tags></v-tags>
                <div class="content">
                    <!-- <router-view> -->
                    <router-view v-slot="{ Component }">
                        <!-- <transition name="move" mode="out-in"> -->
                            <!-- <keep-alive :include="tagsList"> -->
                                <component :is="Component" />
                            <!-- </keep-alive> -->
                        <!-- </transition> -->
                    </router-view>
                    <el-backtop target=".content" :right="5" :bottom="50"></el-backtop>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import vHeader from "@/components/Header";
import vSidebar from "@/components/Sidebar";
import vTags from "@/components/Tags.vue";
export default {
    components: {
        vHeader,
        vSidebar,
        vTags
    },
    computed: {
        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
        // collapse() {
        //     return this.$store.state.isCollapse;
        // }
    }

};
</script>
<style scoped>
    .el-header {
        position: relative;
        background-color: #324157; 
    }
    .el-main {
        /*overflow-y: scroll;*/
        overflow-y: hidden;
    }
    .el-main::-webkit-scrollbar {
        width: 0;
        /* background-color: #cc9e1f; */
        /* height:calc(50vh); */
    }

    .el-container-1 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        /*border: 1px solid yellow;*/
    }
    .content {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        /*height:calc(100vh - 70px - 50px);*/
        /*height:calc(90%);*/
        width: 100%;
        /* overflow-y: hidden; */
        /*border: 1px solid yellow;*/
    }
    .content-main {
        height: 100%;
        overflow-x: hidden;
        /*border: 1px solid green;*/
    }

</style>