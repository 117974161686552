import {
    createRouter,
    createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: 'guests',
                name: "guests",
                meta: {
                    title: '角色'
                },
                component: () => import("@/components/user/Guest.vue"),
            },
            {
                path: 'profiles/:id',
                name: "Profiles",
                meta: {
                    title: '·档案'
                },
                component: () => import("@/components/profiles/ProfilesInfo.vue"),
            },
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import("@/views/Dashboard.vue")
            },
            // {
            //     path: "/ManageInfo",
            //     name: "manageinfo",
            //     meta: {
            //         title: '管理员档案'
            //     },
            //     component: () => import("@/views/profiles/ManageInfo.vue")
            // },             
            // {
            //     path: "/TeacherInfo",
            //     name: "teacherinfo",
            //     meta: {
            //         title: '教师档案'
            //     },
            //     component: () => import("@/views/profiles/TeacherInfo.vue")
            // },             
            // {
            //     path: "/StudentInfo",
            //     name: "studentinfo",
            //     meta: {
            //         title: '学生档案'
            //     },
            //     component: () => import("@/views/profiles/StudentInfo.vue")
            // },             
            {
                path: 'addProfile/:type/:id',// 0是管理员 2是老师 1是学生,
                // component:AddProfile,
                component: () => import("@/components/profiles/AddProfile.vue"),
                name: "AddProfile",
                meta: {
                    title: '·信息'
                },
                props: true
            },
            {
                path: 'addCourse/:id',
                component: () => import("@/views/course/AddCourse.vue"),
                name: "AddCourse",
                meta: {
                    title: '课程信息'
                },
                props: true
            },
            {
                path: 'addKey/:type/:id/:course_id',
                component: () => import("@/views/keys/AddKey.vue"),
                name: "AddKey",
                meta: {
                    title: '·信息'
                },
                props: true
            },
            {
                path: 'addClass/:type/:id',
                component: () => import("@/views/classroom/AddClass.vue"),
                name: "AddClass",
                meta: {
                    title: '·信息'
                },
                props: true
            },
            {
                path: 'addGroup/:id',
                component: () => import("@/views/group/AddGroup.vue"),
                name: "AddGroup",
                meta: {
                    title: '团课信息'
                },
                props: true
            },
            {
                path: 'newGroup/:id',
                component: () => import("@/views/group/NewGroup.vue"),
                name: "NewGroup",
                meta: {
                    title: '开团'
                },
                props: true
            },
            {
                path: "/KeyLexile",
                name: "keylexile",
                meta: {
                    title: 'Lexile关键字'
                },
                component: () => import("@/views/keys/KeyLexile.vue")
            },
            {
                path: "/KeyCourse",
                name: "keycourse",
                meta: {
                    title: 'Course关键字'
                },
                component: () => import("@/views/keys/KeyCourse.vue")
            },
            {
                path: "/KeyLesson",
                name: "keylesson",
                meta: {
                    title: 'Lesson关键字'
                },
                component: () => import("@/views/keys/KeyLesson.vue")
            },
            {
                path: "/KeySlides",
                name: "KeySlides",
                meta: {
                    title: 'Slides关键字'
                },
                component: () => import("@/views/keys/KeySlides.vue")
            },
            {
                path: "/CourseCustom",
                name: "coursecustom",
                meta: {
                    title: '课程制定'
                },
                component: () => import("@/views/course/CourseCustom.vue")
            },
            {
                path: "/ClassInfo",
                name: "classinfo",
                meta: {
                    title: '教室'
                },
                component: () => import("@/views/classroom/ClassInfo.vue")
            },
            {
                path: "/CityKey",
                name: "citykey",
                meta: {
                    title: '城市代码'
                },
                component: () => import("@/views/classroom/CityKey.vue")
            },
            {
                path: "/CampusName",
                name: "campusname",
                meta: {
                    title: '校区缩写'
                },
                component: () => import("@/views/classroom/CampusName.vue")
            },
            {
                path: "/Groups",
                name: "groups",
                meta: {
                    title: '团课'
                },
                component: () => import("@/views/group/Groups.vue")
            },
            {
                path: "/Learn",
                name: "learn",
                meta: {
                    title: '课堂学习'
                },
                component: () => import("@/views/learn/learn.vue")
            },
            {
                path: '/HomeWork/:class_number/:id',
                component: () => import("@/views/learn/HomeWork.vue"),
                name: "HomeWork",
                meta: {
                    title: '作业'
                },
                props: true
            },
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import('@/views/404.vue')
            },
            {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import('@/views/403.vue')
            },
            {
                path: "/IpType",
                name: "IpType",
                meta: {
                    title: '题目类型'
                },
                component: () => import("@/views/ip/IpType.vue")
            },
            {
                path: "/ip/:type/:id/:course_id",
                name: "Ip",
                meta: {
                    title: '题目内容'
                },
                component: () => import("@/views/ip/IpName.vue")
            },
            {
                path: "/infoipname/:id/:type_id",
                name: "InfoIpName",
                meta: {
                    title: '题目内容'
                },
                component: () => import("@/views/ip/InfoIpName.vue")
            },
        ]
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            title: '登录'
        },
        component: () => import("@/views/register.vue")
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import("@/views/Login.vue")
    },
    {
        path: '/:all*',
        name: '404',
        meta: {
            title: '找不到页面'
        },
        component: () => import('@/views/404.vue')
    }

];

const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | vue-manage-system`;
    const role = localStorage.getItem('ms_token');
    if (!role && to.path === '/register') {
        next();
    } else if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        console.log("role:",role);
        role === 'admin' ?
            next() :
            next('/403');
    } else {
        next();
    }
});

export default router;