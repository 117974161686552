<template>
    <div class="operate-button">
        <el-button type="primary" plain size="small" @click="onSave">保存</el-button>
        <el-button type="default" plain size="small" @click="handleClose" >关闭&nbsp<el-icon :size="16"><Close /></el-icon></el-button>
    </div>

    <div class="img_container">
        <input type="file" name="" id="fileul" accept="image/jpeg,image/jpg,image/png" @change="onChangeImage" />
        <img class="img" :src="img_name"  alt="" @click="onSelFile">
    </div>

    <div class="content-Right">
        <div class="collapse">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content ep-bg-purple-light"/>
                    <el-table
                        stripe
                        size="small"
                        :data="paramentsList"
                        border
                        highlight-current-row
                        max-height="calc(100vh - 160px -130px)"
                        style="width: 100%"
                        :header-cell-style="{background: '#eee'}"
                        :row-style="{height: '0'}"
                        :cell-style="{padding: '0'}"
                        :header-row-style="{height: '0'}"
                    >
                        <el-table-column width="80px" label="项目" prop="item"></el-table-column>
                        <el-table-column label="值" prop="value">
                            <template #default="scope">
                                <el-input v-if="scope.row.editType === 'edit' " v-model="scope.row.value"
                                          style="width:100%;"></el-input>
                                <el-select v-model="scope.row.value" @change="onChange" class="m-2" placeholder="请选择..."
                                           v-else-if="scope.row.editType === 'select' ">
                                    <el-option
                                        v-for="item in scope.row.text"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                                <el-switch v-model="scope.row.value"
                                           v-else-if="scope.row.editType === 'switch' "
                                           :active-text=scope.row.text[0].value
                                           :inactive-text=scope.row.text[1].value
                                ></el-switch>
                                <el-input v-else v-model="scope.row.value" disabled></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {getUserInfo, getUserImage, updateUserProfilesInfo, updateUserProfilesImg} from "@/api/api";


export default {
    name: "AsideRight",
    data() {
        return {
            tabData: [],
            value: '',
            parentName: '',
            paramentsList: [],
            img_name: '',
            img_name_old: '',

            queryParams: {
                // lexile:0,
                name:'',
                nickname:'',
                email:'',
                password:'',
                // cname:'',
                first_name:'',
                last_name:'',
                birthday:'',

                gender:'',
                id_number:'',
                city:'',
                phone:'',

                // ContractStartDate:'',
                // first_fulltime_employer:'',
                // undergraduate_from:'',
                // undergraduate_in:'',
                // undergraduate_major_degree:'',
                // last_graduate_from:'',
                // last_graduate_in:'',
                // expire_date:'',
                // last_graduate_major_degree:'',
                // max_course:0,
                // begin_date:'',
                // now_fulltime_employer:'',
                // photo: '',
                // course_time_available: '',
                // grade: '',
                user_name: ''
            },

        }
    },
    computed: {
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let _this = this;
            getUserInfo({
            }).then(res => {
                this.queryParams = res.result
                this.updateParamentsList()
                // console.log(this.queryParams)

                getUserImage({
                }).then((response) => {
                    // console.log(response)
                    if (response !== undefined &&  response.code === 0) {
                        this.img_name = response.result.img_name
                        this.img_name_old = response.result.img_name

                        // this.updateParamentsList()
                    }
                }).catch(function (error) {
                    _this.$message.error(error + ". 获取头像失败！");
                })
            }).catch(function (error) {
                console.log("error:", error)
            }).finally((fin) => {
                // loading.value = false
            })

        },
        onSave() {
            let i = 0
            this.queryParams.user_name          = this.paramentsList[i++].value
            this.queryParams.email              = this.paramentsList[i++].value
            this.queryParams.name               = this.paramentsList[i++].value
            this.queryParams.first_name         = this.paramentsList[i++].value
            this.queryParams.last_name          = this.paramentsList[i++].value
            this.queryParams.nickname           = this.paramentsList[i++].value
            this.queryParams.birthday           = this.paramentsList[i++].value
            this.queryParams.gender             = this.paramentsList[i++].value
            this.queryParams.id_number          = this.paramentsList[i++].value
            this.queryParams.phone              = this.paramentsList[i++].value
            this.queryParams.city              = this.paramentsList[i++].value
            // this.queryParams.undergraduate_from             = this.paramentsList[i++].value
            // this.queryParams.undergraduate_major_degree     = this.paramentsList[i++].value
            // this.queryParams.undergraduate_in               = this.paramentsList[i++].value
            // this.queryParams.lexile                         = this.paramentsList[i++].value
            // this.queryParams.begin_date                     = this.paramentsList[i++].value
            // this.queryParams.first_fulltime_employer        = this.paramentsList[i++].value
            // this.queryParams.last_graduate_from             = this.paramentsList[i++].value
            // this.queryParams.last_graduate_major_degree     = this.paramentsList[i++].value
            // this.queryParams.last_graduate_in               = this.paramentsList[i++].value
            // this.queryParams.max_course                     = this.paramentsList[i++].value
            // this.queryParams.expire_date                    = this.paramentsList[i++].value
            // this.queryParams.now_fulltime_employer          = this.paramentsList[i++].value

            let _this = this
            updateUserProfilesInfo({
                dataString: JSON.stringify(this.queryParams)
            }).then((response) => {
                localStorage.setItem("ms_userimg", this.img_name);
                // console.log("response:", response)
                this.$message.success(response.message);


                let obj = {}
                obj.img_name = this.img_name
                updateUserProfilesImg({
                    dataString: JSON.stringify(obj)
                }).then((response) => {
                    localStorage.setItem("ms_userimg", this.img_name);
                    // console.log("response:", response)
                    this.$message.success(response.message);
                }).catch(function (error) {
                    _this.$message.error(error + ". 更新头像信息失败！");
                });

            }).catch(function (error) {
                _this.$message.error(error + ". 更新信息失败！");
            });

            this.$emit('func',this.img_name)
        },

        // 特殊处理
        onChange(val) {
        },
        handleOpen() {
        },
        handleClose() {
            this.$emit('func','')
        },
        onChangeImage(e) {
            var file = e.target.files[0];
            var reader = new FileReader();
            var that = this;
            reader.readAsDataURL(file);
            reader.onload = function(e) {
                that.img_name = this.result
            }
        },
        onSelFile() {
            document.getElementById("fileul").click();
        },
        updateParamentsList(){
            this.paramentsList = [
                {
                    item: '注册名',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.user_name,
                },
                {
                    item: '注册信箱',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.email
                },
                {
                    item: '中文全名',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.name
                },
                {
                    item: '拼音姓',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.first_name
                },
                {
                    item: '拼音名',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.last_name
                },
                {
                    item: '昵称',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.nickname
                },
                {
                    item: '生日',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.birthday
                },
                {
                    item: '性別',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.gender
                },
                {
                    item: '身份证号码',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.id_number
                },
                {
                    item: '联系电话',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.phone
                },
                {
                    item: '居住城市',
                    type: 'text',
                    editType: 'text',
                    value: this.queryParams.city
                },

                // {
                //     item: '本科院校',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.undergraduate_from
                // },
                // {
                //     item: '本科专业',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.undergraduate_major_degree
                // },
                // {
                //     item: '本科毕业年',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.undergraduate_in
                // },
                // {
                //     item: '自评Lexile',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.lexile
                // },
                // {
                //     item: '合约开始日期',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.begin_date
                // },
                // {
                //     item: '第一雇主',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.first_fulltime_employer
                // },
                // {
                //     item: '最高毕业院校',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.last_graduate_from
                // },
                // {
                //     item: '最高毕业院校专业',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.last_graduate_major_degree
                // },
                // {
                //     item: '最高毕业年份',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.last_graduate_in
                // },
                // {
                //     item: '每周上课次数',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.max_course
                // },
                // {
                //     item: '合约到期日',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.expire_date
                // },
                // {
                //     item: '当前雇主',
                //     type: 'text',
                //     editType: 'text',
                //     value: this.queryParams.now_fulltime_employer
                // },
            ]
        },
    },
}
</script>

<style scoped>
/*.el-row {*/
/*    !*margin: 10px 0;*!*/
/*    height: calc(100vh - 60px);*/
/*}*/
.content-Right {
    background-color: #EBF2F6;
    padding: 1px;
    margin: 1px;
    width: 285px;
    height: calc(100vh - 65px - 10px);
    /*height: calc(100vh - 140px - 10px);*/
}

.collapse {
    background-color: white;
    width: 100%;
    height: calc(100vh - 45px - 32px);
    /*height: calc(100vh - 120px - 32px);*/
}

.el-button {
    margin: 4px;
}

.operate-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
}

.file {
    position: relative;
    display: inline-block;
    background: #D0EEFF;
    border: 1px solid #99D3F5;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: #1E88C7;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}
.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
}
.file:hover {
    background: #AADFFD;
    border-color: #78C3F3;
    color: #004974;
    text-decoration: none;
}

#fileul{
    display: none;
}
.img {
    width: 180px;
    height: 180px;
}
.img_container {
    text-align: left;
}
.el-input {
    width: 100%;
}

</style>