/*
功能：装置参数
 */
import {ElMessage} from "element-plus";

export  default  {
    namespaced  :  true,

    state: {
        //保持每一个批注
        treeStructure: [],
        //
        // editUpdated: 0,
        // panelUpdated: 0,

        deletePanelNode: {},
        updateEditNode: {},
        updatePanelNode: {},

        scrollToComment: 0,

        selectComment: '',
        yellow: {},
    },
    getters: {
        getNotes(state){
            return state.treeStructure
        },
        getNoteCount(state){
            return state.treeStructure.length
        },
        // getEditUpdated(state){
        //     return state.editUpdated
        // },
        // getPanelUpdated(state){
        //     return state.panelUpdated
        // },
        getDeleteNote(state){
            return state.deletePanelNode
        },
        getUpdateEditNote(state){
            return state.updateEditNode
        },
        getUpdatePanelNote(state){
            return state.updatePanelNode
        },
        getScrollToComment(state){
            return state.scrollToComment
        },
        getSelectComment(state){
            return state.selectComment
        },
        getYellow(state){
            return state.yellow
        },
    },
    mutations: {
        setNotes(state, data) {
            state.treeStructure = data;
        },
        addNote(state, data) {
            state.treeStructure.push(data);
        },
        // setEditNote(state, data) {
        setDeleteNote(state, data) {
            state.deletePanelNode = data
        },
        setUpdateEditNote(state, data) {
            state.updateEditNode = data
        },
        setUpdatePanelNote(state, data) {
            state.updatePanelNode = data
        },
        deleteNote(state, data) {
            for(let i = 0; i < state.treeStructure.length; i++){
                if(state.treeStructure[i].commentId === data.commentId){
                    state.treeStructure.splice(i,1)
                    return
                }
            }
            // return false
        },
        updateNode(state, data) {
            for(let i = 0; i < state.treeStructure.length; i++){
                if(state.treeStructure[i].commentId === data.commentId){
                    state.treeStructure[i].comment = data.comment
                    return;
                }
            }
        },
        // updateEditUpdated(state,data){
        //     state.editUpdated += 1
        // },
        // updatePanelUpdated(state,data){
        //     state.panelUpdated += 1
        // },
        setScrollToComment(state,data){
            state.scrollToComment = data
        },
        setSelectComment(state,data){
            state.selectComment = data
        },
        setYellow(state,data){
            state.yellow = data
        }
    },
    actions: {
        setNotes(context,data)  {
            context.commit('setNotes', data)
        },
    },
}