<template>
    <div class="header">
        <div class="logo">后台管理系统</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 消息中心 -->
<!--                <el-tooltip-->
<!--                    effect="dark"-->
<!--                    :content="message?`有${message}条未读消息`:`没有消息`"-->
<!--                    placement="bottom"-->
<!--                >-->
<!--                    <el-badge :is-dot="message" class="item" >-->
<!--                        <el-button size="small" type="primary">-->
<!--                            <el-icon :color="message?'#fff':'#000'"><Bell /></el-icon>-->
<!--                        </el-button>-->
<!--                    </el-badge>-->
<!--                </el-tooltip>-->
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img :src=userimage  alt="" @click="onOpenUserInfo" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-button text @click="onOpenUserInfo" v-if="username !== 'guest'">我的信息</el-button>
                            <el-dropdown-item divided command="ChangePassword">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>

    <el-drawer
        v-model="dialog"
        title="我的档案"
        :before-close="handleClose"
        direction="rtl"
        class="demo-drawer"
        ref="drawer"
        size="345"
        :with-header="false"
    >
        <AsideRight @func="onSaveRight" :key="AsideRightOpenCount"></AsideRight>
    </el-drawer>

    <el-dialog title="修改密码" v-model="editVisible" width="30%">
        <el-form ref="form" :model="form" label-width="70px">
            <el-form-item label="旧密码">
                <el-input type="password" show-password v-model="form.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input type="password" show-password v-model="form.newPassword"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCancelPassword">取 消</el-button>
          <el-button type="primary" @click="onConfirmResetPassword">确 定</el-button>
        </span>
        </template>
    </el-dialog>

</template>
<script>
import {getUserImage, resetPassword2} from "@/api/api";
import AsideRight from "@/views/AsideRight.vue";
import { ElMessage, ElMessageBox} from 'element-plus'

export default {
    data() {
        return {
            fullscreen: false,
            name: "linxin",
            message: 1,

            dialog: false,
            userimage: require('@/assets/img/img.jpg'),
            AsideRightOpenCount: 0,

            editVisible: false,
            form: {
                oldPassword: '',
                newPassword: '',
            },

        };
    },
    mounted() {
        this.getuserimage()
    },
    computed: {
        username() {
            let username = localStorage.getItem("ms_username");
            return username ? username : this.name;
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command === "loginout") {
                localStorage.removeItem("ms_username");
                this.$router.push("/login");
            }else if(command === 'ChangePassword'){
                this.onChangePassword()
            }
        },
        getuserimage() {
            let userid = localStorage.getItem("ms_userid");
            getUserImage({
            }).then(res => {
                if (res.result) {
                    if(res.result.img_name.length > 1) {
                        this.userimage = res.result.img_name
                    }
                }
            })
        },
        onOpenUserInfo(){
            this.dialog = true
            this.AsideRightOpenCount += 1
        },
        handleClose(){
        },
        onSaveRight(img){
            if(img !== '')
                this.userimage = img
            this.dialog = false
        },
        onChangePassword(){
            this.editVisible = true
        },
        onCancelPassword () {
            this.editVisible = false
            ElMessage({
                type: 'info',
                message: '已取消',
            })
        },
        onConfirmResetPassword () {
            if(this.form.oldPassword.length < 1){
                ElMessageBox.alert('旧密码不能为空！', '信息', {
                    confirmButtonText: 'OK',
                })
                return
            }
            if(this.form.newPassword.length < 8){
                ElMessageBox.alert('新密码长度不能小于8个字符！', '信息', {
                    confirmButtonText: 'OK',
                })
                return
            }
            this.editVisible = false
            let _this = this
            resetPassword2({
                id: localStorage.getItem("ms_userid"),
                user_name: localStorage.getItem("ms_username"),
                password: this.form.oldPassword,
                password_new: this.form.newPassword
            }).then(res => {
                // console.log(res)
                this.$message.success(res.message);
            }).catch(function (error) {
                // console.log("error:", error)
                _this.$message.error(error.response.data.message);
            }).finally((fin) => {
            })
        }
    },
    components: {
        AsideRight
    }
};
</script>
<style scoped>

.header .logo {
    font-size: 22px;
    color: #fff;

    float: left;
    width: 250px;
    line-height: 70px;
}

.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {

    display: flex;
    height: 70px;
    align-items: center;
}

.user-name {
    margin-left: 20px;
}

.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}


.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
.item {
  margin-right: 10px;
}
</style>
